import { type Config } from 'tailwindcss'

/**
 * * This is where we can define new colors, fonts, etc. that we want to use in our app.
 */
export const extendedTheme = {
	fontFamily: {
		'arboria-book': ['Arboria-Book', 'sans-serif'],
		'arboria-bold': ['Arboria-Bold', 'sans-serif'],
		'arboria-thin': ['Arboria-Thin', 'sans-serif'],
	},
	colors: {
		'cadet-blue': '#AAB2C8',
		'forest-green': '#228C22',
		'verified-green': '#53a371',
		'unverified-yellow': '#686014',
		'hero-pattern-peach': '#f1edeb',
		crimson: '#D8162A',
		'seashell-peach': '#FFF1EA',
		'yankees-blue': '#142242',
		'user-nav-light-blue': '#f5f6fa',
		border: 'hsl(var(--border))',
		input: {
			DEFAULT: 'hsl(var(--input))',
			invalid: 'hsl(var(--input-invalid))',
		},
		ring: {
			DEFAULT: 'hsl(var(--ring))',
			invalid: 'hsl(var(--foreground-destructive))',
		},
		background: 'hsl(var(--background))',
		foreground: {
			DEFAULT: 'hsl(var(--foreground))',
			destructive: 'hsl(var(--foreground-destructive))',
		},
		primary: {
			DEFAULT: 'hsl(var(--primary))',
			foreground: 'hsl(var(--primary-foreground))',
		},
		secondary: {
			DEFAULT: 'hsl(var(--secondary))',
			foreground: 'hsl(var(--secondary-foreground))',
		},
		destructive: {
			DEFAULT: 'hsl(var(--destructive))',
			foreground: 'hsl(var(--destructive-foreground))',
		},
		muted: {
			DEFAULT: 'hsl(var(--muted))',
			foreground: 'hsl(var(--muted-foreground))',
		},
		accent: {
			DEFAULT: 'hsl(var(--accent))',
			foreground: 'hsl(var(--accent-foreground))',
		},
		popover: {
			DEFAULT: 'hsl(var(--popover))',
			foreground: 'hsl(var(--popover-foreground))',
		},
		card: {
			DEFAULT: 'hsl(var(--card))',
			foreground: 'hsl(var(--card-foreground))',
		},
	},
	backgroundImage: {
		'hero-pattern': "url('/img/bg.png')",
		'hero-pattern-small': "url('/img/bg-small.png')",
		'card-gold': "url('/img/card-gold-bg.png')",
		'card-copper': "url('/img/card-copper-bg.svg')",
	},
	boxShadow: {
		menu: 'inset 0 -2px 0 0 #d8162a',
	},
	gridTemplateColumns: {
		// 3 column repeat with min:0 max:auto
		'3-0-auto': 'repeat(3, minmax(0, auto))',
		// 4 column repeat with min:0 max:auto
		'4-0-auto': 'repeat(4, minmax(0, auto))',
		// Complex site-specific column configuration
		footer: '200px minmax(900px, 1fr) 100px',
	},
} satisfies Config['theme']
